<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <div style="height: calc(100vh - 0px) !important; overflow: auto">
      <v-layout class="custom-header">
        <v-flex>
          <h3
            class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
          >
            Serial Numbers
          </h3>
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            class="custom-grey-border custom-bold-button"
            v-on:click="goBack"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </v-flex>
      </v-layout>
      <template>
        <CustomerBarcode> </CustomerBarcode>
      </template>
      <template>
        <PropertyBarcode> </PropertyBarcode>
      </template>
      <template>
        <SupplierBarcode> </SupplierBarcode>
      </template>
      <!--   <template>
        <QuotationBarcode> </QuotationBarcode>
      </template> -->
      <!-- <template>
        <ContractBarcode> </ContractBarcode>
      </template> -->
      <!--    <template>
        <InvoiceBarcode> </InvoiceBarcode>
      </template> -->
      <!--   <template>
        <PaymentBarcode> </PaymentBarcode>
      </template> -->
      <template>
        <TechniciansBarcode> </TechniciansBarcode>
      </template>
      <!--   <template>
        <LeaveBarcode> </LeaveBarcode>
      </template> -->
      <template>
        <ProductBarcode> </ProductBarcode>
      </template>
      <template>
        <ServiceBarcode> </ServiceBarcode>
      </template>
      <!-- <template>
        <DeliveryBarcode> </DeliveryBarcode>
      </template> -->
      <!--   <template>
        <IncidentReportBarcode> </IncidentReportBarcode>
      </template> -->
      <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 85vh; position: relative"
          >
            <v-card
              flat
              class="custom-setting-height custom-grey-border remove-border-radius"
            >
              <v-card-title class="headline grey lighten-4">
                <v-row>
                  <v-col md="6" class="py-0 my-auto">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                      style="margin: 3.5px 0px"
                    >
                      Customer #
                    </h3>
                  </v-col>
                  <template v-if="getPermission('setting:update')">
                    <v-col md="6" class="text-right py-0">
                      <v-btn
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="updateCustomerSetting"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Update</span>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>
              <v-card-text
                class="p-6 font-size-16"
                :class="{ 'pointer-events-none': !getPermission('setting:update') }"
              >
                <v-row>
                  <v-col md="4" class="pb-4">
                    <label>Prefix</label>
                    <v-text-field
                      v-model.trim="setting.prefix"
                      dense
                      filled
                      label="Prefix"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="pb-4">
                    <label>Next Number</label>
                    <v-text-field
                      v-model.trim="setting.next_number"
                      dense
                      filled
                      label="Next Number"
                      solo
                      flat
                      type="number"
                      min="0"
                      max="999"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="pb-4">
                    <label>Number Length</label>
                    <v-text-field
                      v-model.trim="setting.number_length"
                      dense
                      filled
                      label="Number Length"
                      solo
                      flat
                      type="number"
                      min="0"
                      max="15"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat class="custom-grey-border remove-border-radius mt-4">
              <v-card-title class="headline grey lighten-4">
                <v-row>
                  <v-col md="6" class="py-0 my-auto">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                      style="margin: 3.5px 0px"
                    >
                      Customer Remarks
                    </h3>
                  </v-col>
                  <template v-if="getPermission('setting:update')">
                    <v-col md="6" class="text-right py-0">
                      <v-btn
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="updateCustomerSetting"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Update</span>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>
              <v-card-text
                class="p-6 font-size-16"
                :class="{ 'pointer-events-none': !getPermission('setting:update') }"
              >
                <v-row>
                  <v-col md="12" class="pb-4">
                    <v-textarea
                      v-model.trim="setting.remarks"
                      auto-grow
                      dense
                      filled
                      label="Remarks"
                      solo
                      flat
                      row-height="30"
                      color="cyan"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </perfect-scrollbar> -->
    </div>
  </v-container>
</template>

<script>
/* import { GET, PATCH } from "@/core/services/store/request.module"; */
import CustomerBarcode from "@/view/pages/profile/Setting/Partial/Customer-barcode";
import PropertyBarcode from "@/view/pages/profile/Setting/Partial/Property-barcode";
import SupplierBarcode from "@/view/pages/profile/Setting/Partial/Supplier-barcode";
//import QuotationBarcode from "@/view/pages/profile/Setting/Partial/Quotation-barcode";
// import ContractBarcode from "@/view/pages/profile/Setting/Partial/Contract-barcode";
/* import InvoiceBarcode from "@/view/pages/profile/Setting/Partial/Invoice-barcode";
import PaymentBarcode from "@/view/pages/profile/Setting/Partial/Payment-barcode"; */
import TechniciansBarcode from "@/view/pages/profile/Setting/Partial/Technicians-barcode";
/* import LeaveBarcode from "@/view/pages/profile/Setting/Partial/Leave-barcode"; */
import ProductBarcode from "@/view/pages/profile/Setting/Partial/Product-barcode";
import ServiceBarcode from "@/view/pages/profile/Setting/Partial/Service-barcode";
// import DeliveryBarcode from "@/view/pages/profile/Setting/Partial/Delivery-barcode";
/* import IncidentReportBarcode from "@/view/pages/profile/Setting/Partial/IncidentReport-barcode"; */

export default {
  name: "profile-customer-setting",
  data() {
    return {
      pageLoading: true,
    };
  },
  methods: {},
  mounted() {},
  components: {
    CustomerBarcode,
    PropertyBarcode,
    SupplierBarcode,
    /*  QuotationBarcode, */
    // ContractBarcode,
    TechniciansBarcode,
    /*  LeaveBarcode, */
    ProductBarcode,
    ServiceBarcode,
    // DeliveryBarcode,
    /*  IncidentReportBarcode,
    InvoiceBarcode,
    PaymentBarcode, */
    /* ContactPersons */
  },
};
</script>
